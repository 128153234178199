import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container } from "react-bootstrap";

import Meta from "../components/meta";
import Layout from "../components/layout";

// markup
const EravelPage = () => {
  return (
    <Layout>
      {/* サイトタイトル */}
      <Meta title="エラベルに掲載されました" />

      <div style={{ zIndex: 10, position: "relative" }}>
        <div className="container">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <a className="breadcrumb__link" href="/">
                  ホーム
                </a>
                <span className="breadcrumb__separator" aria-hidden="true">
                  {" "}
                  &gt;{" "}
                </span>
              </li>
              <li className="breadcrumb__item">
                <a
                  aria-current="page"
                  className="breadcrumb__link"
                  href="/eravel/"
                >
                  エラベルに掲載されました
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <section className="sec position-relative bg-illust">
        {/* <div className="illust illust--1">
          <StaticImage alt="illust" src="../images/illust_01.png" />
        </div>
        <div className="illust illust--2">
          <StaticImage alt="illust" src="../images/illust_02.png" />
        </div> */}
        <Container>
          <div className="text-center mb-md-5 mb-4">
            <StaticImage
              src="../images/img_eravel@2x.png"
              alt="エラベルに掲載されました"
            />
          </div>

          <h2 className="ttl--h2-2 text-primary kosugi text-center">
            このたび、株式会社東京商工リサーチが厳選する
            <br />
            優良企業「ALEVEL」に4年連続で掲載されることになりました。
          </h2>
          <p className="text-center">
            「ALevel（エラベル）」は、経営者の能力、成⻑性、安定性、公開性・総合世評の
            <br />
            分野で細かく評価される企業信用調査「TSR 評点」において、
            <br />
            全国の中小企業で上位8.4％にランクインする優良企業が掲載されています。
            <br />
            これに慢心することなく、皆様方のご期待・ご要望に応えることが出来るよう、
            <br />
            より一層製品・サービス向上に努めてまいります。
            <br />
            引き続き、ご支援・ご愛顧を賜りますよう、どうかよろしくお願い申し上げます。
            <br />
            インターネット版の「インターネットエラベル」に掲載されております。
          </p>
          <div className="text-center mt-4">
            <a
              className="btn btn-secondary"
              href="https://www.tsr-net.co.jp/service/product/tsr_alevel/detail/index.html?id=2228&industry_type_id=&is_recruit=&searchKeyword=%E5%B9%B3%E5%AE%89%E3%82%B3%E3%83%BC%E3%83%9D%E3%83%AC%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3&limit=20&prefecture_id="
              target="_blank"
              rel="noreferrer"
            >
              詳しくはこちら
            </a>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default EravelPage;
